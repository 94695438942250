import { Link } from 'react-router-dom'
import ScoreForm from "../forms/ScoreForm";

import "./HeroSection.css";

export default function HeroSection() {
  return (
    <div id="hero-section">
      <div className="open-logo">
        <img
          src="https://media.complex.com/websites/michelob-ultra-open/images-temp/michelob-open-logo.png"
          alt=""
        />
      </div>
      <div className="welcome-text">
        <p>
          MICHELOB ULTRA IS ENCOURAGING EVERYONE 21+ TO GET OUT AND GOLF THIS
          SUMMER. WHETHER YOU'RE A PRO OR A NOVICE, YOU CAN ENTER HERE FOR A 
          CHANCE TO WIN A TRIP FOR TWO TO THE FIRST ANNUAL COMPLEX PRO-AM IN 
          LAS VEGAS ON NOVEMBER 15, 2024.
        </p>
      </div>
      <ScoreForm />
      <div className="pro-logo">
        <img
          src="https://media.complex.com/websites/michelob-ultra-open/images-temp/pro-am-logo.png"
          alt="complex proam las vegas 11-15-2024"
        />
      </div>
      <div className="legal-text">
        <p>
          NO PURCHASE NECESSARY. ENDS 8/31/24. MUST BE A PERMANENT LEGAL
          RESIDENT OF THE 50 U.S. OR DC, 21 YEARS OF AGE OR OLDER. VOID WHERE
          PROHIBITED. FOR RULES AND COMPLETE DETAILS <Link to="/terms">CLICK HERE</Link>.
        </p>
        <p>
          ENJOY RESPONSIBLY © 2024 ANHEUSER-BUSCH, MICHELOB ULTRA® LIGHT BEER,
          ST. LOUIS, MO.
        </p>
      </div>
    </div>
  );
}
